<script>
import { layoutComputed } from "@/state/helpers";
import { SimpleBar } from "simplebar-vue3";

export default {
  components: {
    SimpleBar,
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
    };
  },
  computed: {
    ...layoutComputed,
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {};
      },
    },
  },

  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse");
      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation();
          let closestCollapse = collapse.parentElement.closest(".collapse");
          if (closestCollapse) {
            let siblingCollapses =
              closestCollapse.querySelectorAll(".collapse");
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                let aChild = siblingCollapse.parentNode.firstChild;
                if (aChild.hasAttribute("aria-expanded")) {
                  aChild.setAttribute("aria-expanded", "false");
                }
                siblingCollapse.classList.remove("show");
              }
            });
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = [];
              let sibling = elem.parentNode.firstChild;
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
              }
              return siblings;
            };
            let siblings = getSiblings(collapse.parentElement);
            siblings.forEach((item) => {
              if (item.childNodes.length > 2)
                item.firstElementChild.setAttribute("aria-expanded", "false");
              let ids = item.querySelectorAll("*[id]");
              ids.forEach((item1) => {
                let aChild = item1.parentNode.firstChild;
                if (aChild.hasAttribute("aria-expanded")) {
                  aChild.setAttribute("aria-expanded", "false");
                }
                item1.classList.remove("show");
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a");

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false");
                  });
                }
              });
            });
          }
        });

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation();
          let childCollapses = collapse.querySelectorAll(".collapse");
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse;
            childCollapseInstance.hide();
          });
        });
      });
    }
  },

  methods: {
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition =
          document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if (this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop =
              currentPosition + 300;
      }
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document
            .querySelector("#navbar-nav")
            .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add(
                "active"
              );
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (
                parentCollapseDiv.parentElement.closest(
                  ".collapse.menu-dropdown"
                )
              ) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 0);
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <div id="two-column-menu"></div>

    <template v-if="layoutType === 'twocolumn'">
      <SimpleBar class="navbar-nav" id="navbar-nav">
        <li class="menu-title">
          <span data-key="t-menu"> {{ $t("t-menu") }}</span>
        </li>
        <li class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarDashboards"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarDashboards"
          >
            <i class="ri-dashboard-2-line"></i>
            <span data-key="t-dashboards"> {{ $t("t-dashboards") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarDashboards">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link
                  to="/dashboard/analytics"
                  class="nav-link custom-abc"
                  data-key="t-analytics"
                >
                  {{ $t("t-analytics") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/dashboard/crm"
                  class="nav-link"
                  data-key="t-crm"
                >
                  {{ $t("t-crm") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/" class="nav-link" data-key="t-ecommerce">
                  {{ $t("t-ecommerce") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/dashboard/crypto"
                  class="nav-link"
                  data-key="t-crypto"
                >
                  {{ $t("t-crypto") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/dashboard/projects"
                  class="nav-link"
                  data-key="t-projects"
                >
                  {{ $t("t-projects") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <!-- end Dashboard Menu -->
        <li class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarApps"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarApps"
          >
            <i class="ri-apps-2-line"></i>
            <span data-key="t-apps"> {{ $t("t-apps") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarApps">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link
                  to="/calendar"
                  class="nav-link"
                  data-key="t-calendar"
                >
                  {{ $t("t-calendar") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/chat" class="nav-link" data-key="t-chat">
                  {{ $t("t-chat") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/mailbox"
                  class="nav-link"
                  data-key="t-mailbox"
                >
                  {{ $t("t-mailbox") }}
                </router-link>
              </li>
              <li class="nav-item">
                <a
                  href="#sidebarEcommerce"
                  class="nav-link"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarEcommerce"
                  data-key="t-ecommerce"
                >
                  {{ $t("t-ecommerce") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarEcommerce">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link
                        to="/ecommerce/products"
                        class="nav-link"
                        data-key="t-products"
                      >
                        {{ $t("t-products") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/ecommerce/product-details"
                        class="nav-link"
                        data-key="t-product-Details"
                      >
                        {{ $t("t-product-Details") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/ecommerce/add-product"
                        class="nav-link"
                        data-key="t-create-product"
                      >
                        {{ $t("t-create-product") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/ecommerce/orders"
                        class="nav-link"
                        data-key="t-orders"
                      >
                        {{ $t("t-orders") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/ecommerce/order-details"
                        class="nav-link"
                        data-key="t-order-details"
                      >
                        {{ $t("t-order-details") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/ecommerce/customers"
                        class="nav-link"
                        data-key="t-customers"
                      >
                        {{ $t("t-customers") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/ecommerce/shopping-cart"
                        class="nav-link"
                        data-key="t-shopping-cart"
                      >
                        {{ $t("t-shopping-cart") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/ecommerce/checkout"
                        class="nav-link"
                        data-key="t-checkout"
                      >
                        {{ $t("t-checkout") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/ecommerce/sellers"
                        class="nav-link"
                        data-key="t-sellers"
                      >
                        {{ $t("t-sellers") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/ecommerce/seller-details"
                        class="nav-link"
                        data-key="t-sellers-details"
                      >
                        {{ $t("t-sellers-details") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a
                  href="#sidebarProjects"
                  class="nav-link"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarProjects"
                  data-key="t-projects"
                >
                  {{ $t("t-projects") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarProjects">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link
                        to="/apps/projects-list"
                        class="nav-link"
                        data-key="t-list"
                      >
                        {{ $t("t-list") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/apps/projects-overview"
                        class="nav-link"
                        data-key="t-overview"
                      >
                        {{ $t("t-overview") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/apps/projects-create"
                        class="nav-link"
                        data-key="t-create-project"
                      >
                        {{ $t("t-create-project") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a
                  href="#sidebarTasks"
                  class="nav-link"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarTasks"
                  data-key="t-tasks"
                >
                  {{ $t("t-tasks") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarTasks">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link
                        to="/apps/tasks-kanban"
                        class="nav-link"
                        data-key="t-kanbanboard"
                      >
                        {{ $t("t-kanbanboard") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/apps/tasks-list-view"
                        class="nav-link"
                        data-key="t-list-view"
                      >
                        {{ $t("t-list-view") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/apps/tasks-details"
                        class="nav-link"
                        data-key="t-task-details"
                      >
                        {{ $t("t-task-details") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a
                  href="#sidebarCRM"
                  class="nav-link"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarCRM"
                  data-key="t-crm"
                >
                  {{ $t("t-crm") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarCRM">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link
                        to="/apps/crm-contacts"
                        class="nav-link"
                        data-key="t-contacts"
                      >
                        {{ $t("t-contacts") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/apps/crm-companies"
                        class="nav-link"
                        data-key="t-companies"
                      >
                        {{ $t("t-companies") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/apps/crm-deals"
                        class="nav-link"
                        data-key="t-deals"
                      >
                        {{ $t("t-deals") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/apps/crm-leads"
                        class="nav-link"
                        data-key="t-leads"
                      >
                        {{ $t("t-leads") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a
                  href="#sidebarCrypto"
                  class="nav-link"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarCrypto"
                  data-key="t-crypto"
                >
                  {{ $t("t-crypto") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarCrypto">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link
                        to="/crypto/transactions"
                        class="nav-link"
                        data-key="t-transactions"
                      >
                        {{ $t("t-transactions") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/crypto/buy-sell"
                        class="nav-link"
                        data-key="t-buy-sell"
                      >
                        {{ $t("t-buy-sell") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/crypto/orders"
                        class="nav-link"
                        data-key="t-orders"
                      >
                        {{ $t("t-orders") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/crypto/wallet"
                        class="nav-link"
                        data-key="t-my-wallet"
                      >
                        {{ $t("t-my-wallet") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/crypto/ico"
                        class="nav-link"
                        data-key="t-ico-list"
                      >
                        {{ $t("t-ico-list") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/crypto/kyc"
                        class="nav-link"
                        data-key="t-kyc-application"
                      >
                        {{ $t("t-kyc-application") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a
                  href="#sidebarInvoices"
                  class="nav-link"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarInvoices"
                  data-key="t-invoices"
                >
                  {{ $t("t-invoices") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarInvoices">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link
                        to="/invoices/list"
                        class="nav-link"
                        data-key="t-list-view"
                      >
                        {{ $t("t-list-view") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/invoices/detail"
                        class="nav-link"
                        data-key="t-details"
                      >
                        {{ $t("t-details") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/invoices/create"
                        class="nav-link"
                        data-key="t-create-invoice"
                      >
                        {{ $t("t-create-invoice") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a
                  href="#sidebarTickets"
                  class="nav-link"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarTickets"
                  data-key="t-supprt-tickets"
                >
                  {{ $t("t-supprt-tickets") }}
                </a>
                <div class="collapse menu-dropdown" id="sidebarTickets">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link
                        to="/apps/tickets-list"
                        class="nav-link"
                        data-key="t-list-view"
                      >
                        {{ $t("t-list-view") }}
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/apps/tickets-details"
                        class="nav-link"
                        data-key="t-ticket-details"
                      >
                        {{ $t("t-ticket-details") }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </SimpleBar>
    </template>

    <template v-else>
      <ul class="navbar-nav h-100" id="navbar-nav">
        <li class="menu-title">
          <span data-key="t-menu"> {{ $t("t-menu") }}</span>
        </li>
        <li class="nav-item">
          <router-link to="/" class="nav-link menu-link">
            <i class="ri-dashboard-2-line"></i>
            <span data-key="t-dashboards"> {{ $t("t-dashboards") }}</span>
          </router-link>
        </li>
        <!--  -->
        <!-- <li class="nav-item">
          <router-link
            to="/invoices/list"
            class="nav-link menu-link"
            role="button"
          >
            <i class="ri-apps-2-line"></i>
            <span data-key="t-apps"> {{ $t("t-invoices") }}</span>
          </router-link>
        </li> -->
       

        <li class="nav-item">
          <router-link
            to="/clients/list"
            class="nav-link menu-link"
            role="button"
          >
            <i class="ri-team-line"></i>
            <span> {{ $t("t-clients") }}</span>
          </router-link>
        </li>

        <li class="nav-item">
          <a
            href="#sidebarInvoices"
            class="nav-link"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarInvoices"
            data-key="t-vnd"
          >
            <i class="ri-group-fill"></i>
            {{ $t("t-vnd") }}
          </a>
          <div class="collapse menu-dropdown" id="sidebarInvoices">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link
                  to="/vendor/list"
                  class="nav-link"
                  data-key="t-list-view"
                >
                  {{ $t("t-vendors") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/vendor-quotes"
                  class="nav-link"
                  data-key="t-details"
                >
                  {{ $t("t-vendor-quotes") }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item">
          <router-link
            to="/currency/list"
            class="nav-link menu-link"
            role="button"
          >
            <i class="ri-money-dollar-circle-line"></i>
            <span> {{ $t("t-currency") }}</span>
          </router-link>
        </li>

         <li class="nav-item">
          <a
            href="#sidebarTasks"
            class="nav-link"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarTasks"
            data-key="t-tasks"
          >
            <i class="ri-apps-2-line"></i>
            <span data-key="t-apps"> {{ $t("t-rfq") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarTasks">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link
                  to="/request-for-quotes"
                  class="nav-link"
                  data-key="t-rfql"
                >
                  {{ $t("t-rfql") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/"
                  class="nav-link"
                  data-key="t-rfqr"
                >
                  {{ $t("t-rfqr") }}
                </router-link>
              </li>
              <!-- <li class="nav-item">
                <router-link
                  to="/apps/tasks-details"
                  class="nav-link"
                  data-key="t-task-details"
                >
                  {{ $t("t-task-details") }}
                </router-link>
              </li> -->
            </ul>
          </div>
        </li>
        <!-- USERS  ROUTE -->
        <li class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarApps"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarApps"
          >
            <i class="ri-user-settings-line"></i>
            <span data-key="t-users"> {{ "Users" }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarApps">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/user/list" class="nav-link" data-key="t-list">
                  {{ "List" }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/user/roles"
                  class="nav-link"
                  data-key="t-categories"
                >
                  {{ "Roles" }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <!-- END USERS ROUTE -->
        <!-- PRODUCTS ROUTE -->
        <li class="nav-item">
          <a
            class="nav-link menu-link"
            href="#sidebarApps"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="sidebarApps"
          >
            <i class="ri-archive-line"></i>
            <span data-key="t-products"> {{ $t("t-products") }}</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarApps">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link
                  to="/product/list"
                  class="nav-link"
                  data-key="t-list"
                >
                  {{ $t("t-list") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/product/categories"
                  class="nav-link"
                  data-key="t-categories"
                >
                  {{ "Categories" }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/product/brands"
                  class="nav-link"
                  data-key="t-brands"
                >
                  {{ "Brands" }}
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <!--END PRODUCTS ROUTE -->
        <li class="nav-item">
          <router-link
            to="/invoices/list"
            class="nav-link menu-link"
            role="button"
          >
            <i class="ri-questionnaire-line"></i>
            <span> {{ $t("t-extimates") }}</span>
          </router-link>
        </li>
      </ul>
    </template>
  </div>
  <!-- Sidebar -->
</template>
